import { DateTime } from "luxon";

type Format = "date" | "datetime" | "time" | "weekday";

export const formatDate = (date: string | DateTime, format: Format): string => {
  if (!DateTime.isDateTime(date)) {
    date = DateTime.fromISO(date);
  }

  switch (format) {
    case "date":
      return date.toFormat("MM/dd/yyyy");
    case "time":
      return date.toFormat("h:mma");
    case "weekday":
      return date.toFormat("cccc");
    default:
      throw new Error("Invalid format");
  }
};
