import React from "react";
import { DateTime } from "luxon";
import DialogActions from "@material-ui/core/DialogActions";
import { ExportToCsv } from "export-to-csv";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import Button from "@ui-kit/Button";
import { API, Models } from "@services/api";
import { formatPhone } from "@helpers/format-phone";
import { ReportItem, ExportCsvProps } from "./types";
import { BookingRequest } from "@models/BookingRequest";
import { formatDate } from "@utils/formatDate";
import { must } from "@utils/must";
import { t } from "@i18n";

const formatRequest = (request: BookingRequest) => {
  const o: Partial<ReportItem> = {};

  const timeFrom = DateTime.fromISO(request.bookingSlot.shift.dateTimeFrom, {
    zone: request.facilityCard.getTimezone(),
  });
  const createdAt = DateTime.fromISO(request.createdAt, {
    zone: request.facilityCard.getTimezone(),
  });

  o.service_reservation_id = request.publicId;
  o.service_date_booked = formatDate(createdAt, "date");
  o.service_date = formatDate(timeFrom, "date");
  o.service_day = formatDate(timeFrom, "weekday");
  o.service_drop_off_time = formatDate(timeFrom, "time");
  o.service_payment_amount = `${request.bookingSlot.serviceFee / 100}`;
  o.service_date_cancelled = request.isCancelled()
    ? formatDate(must(request.cancelledAt), "date")
    : "";
  o.facility_name = request.facilityCard.name;
  o.facility_city = request.facilityCard.address.city;
  o.facility_state = request.facilityCard.address.state;
  o.facility_zip_code = request.facilityCard.address.zip;
  o.parent_first_name = request.parent?.firstName;
  o.parent_last_name = request.parent?.lastName;
  o.parent_phone = formatPhone(must(request.parent?.phone)) || "";
  o.parent_email = request.parent?.email;

  return o;
};

const loadBookingRequestsReportData = async (corporationId: number) => {
  let result: Partial<ReportItem>[] = [];

  let flag = true;
  let page = 0;

  while (flag) {
    const start = page * 500;
    const end = start + 500;

    const requests = await API.booking.getList({
      start,
      end,
      sort: Models.BookingRequestsSortableColumns.Id,
      order: "ASC",
      corpBenefitAccountId: [corporationId],
    });

    result = result.concat(requests.map(formatRequest));
    page += 1;

    if (requests.length < 500) {
      flag = false;
    }
  }

  return result;
};

const headers = [
  t("dashboard.reservationsTable.columns.id"),
  t("dashboard.reservationsTable.columns.dateBooked"),
  t("dashboard.reservationsTable.columns.serviceDate"),
  t("dashboard.reservationsTable.columns.serviceDay"),
  t("dashboard.reservationsTable.columns.timeFromCorporations"),
  t("dashboard.reservationsTable.columns.coPayment"),
  t("dashboard.reservationsTable.columns.dateCancelled"),
  t("dashboard.reservationsTable.columns.facilityName"),
  t("dashboard.reservationsTable.columns.facilityCity"),
  t("dashboard.reservationsTable.columns.facilityState"),
  t("dashboard.reservationsTable.columns.facilityZip"),
  t("dashboard.reservationsTable.columns.parentFirstName"),
  t("dashboard.reservationsTable.columns.parentLastName"),
  t("dashboard.reservationsTable.columns.parentPhone"),
  t("dashboard.reservationsTable.columns.parentEmail"),
];

export const DownloadHistoryDialog: React.FC<ExportCsvProps> = ({
  onClose,
  corporationId,
}) => {
  return (
    <Dialog open onClose={onClose}>
      <div style={{ width: 500 }}>
        <Typography variant="h4" bolded paragraph>
          Download reservation history?
        </Typography>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              const rows = await loadBookingRequestsReportData(corporationId);
              const csvExporter = new ExportToCsv({
                filename: "reservations",
                headers,
                useKeysAsHeaders: false,
                showLabels: true,
              });

              csvExporter.generateCsv(rows);
              onClose();
            }}
            variant="contained"
            color="secondary"
          >
            Download
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
