import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import Typography from "@ui-kit/Typography";
import { QUERIES } from "@constants/queries";
import { API } from "@services/api";
import { PathParams } from "./types";
import { BookingRequestsTable } from "./Table";
import { Spacer } from "@ui-kit/Spacer";

export const BookingRequests: React.FC = () => {
  const { corporationId } = useParams<PathParams>();
  const { data: account } = useQuery(
    [QUERIES.GET_FACILITY_CARD, corporationId],
    () =>
      API.users.accountFindOne({
        id: parseInt(corporationId, 10),
      }),
    {
      refetchOnMount: "always",
    },
  );

  return (
    <div>
      <Typography variant="h4">{account?.name}</Typography>
      <Spacer size="md" />
      <BookingRequestsTable />
    </div>
  );
};
