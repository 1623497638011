import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    maxWidth: 1600,
    minWidth: 1000,
    padding: "30px 15px",
    margin: "0 auto",
  },
});
