import React from "react";
import track from "react-tracking";
import { Switch, Route, useParams, Redirect } from "react-router-dom";

import { BookingRequests } from "./BookingRequests";
import { createCss } from "./styles";
import { PathParams } from "./types";
import { useUserStore } from "@store/UserStore";
import { must } from "@utils/must";
import { isAdmin } from "@selectors";

const CorporationDashboard: React.FC = () => {
  const { corporationId } = useParams<PathParams>();
  const [{ user }] = useUserStore();
  const css = createCss();

  if (must(user?.account.id) != parseInt(corporationId, 10) && !isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <div css={css.root}>
      <Switch>
        <Route
          path="/dashboard/corporations/:corporationId/reservations"
          component={BookingRequests}
        />
      </Switch>
    </div>
  );
};

export default track({
  page: "Corporation Dashboard",
})(CorporationDashboard);
