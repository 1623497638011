import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  cell: {
    fontSize: 10,
    lineHeight: "12px",

    "&.MuiTableCell-head": {
      fontWeight: 600,
      verticalAlign: "top",
    },
  },

  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
